import { getRequestEnvUrl } from './env';
import { paramToString } from './paramParse';
import { getEnv, ssoContractsUrl } from './env';
import { setTokenCookie } from './serverRequest';
export const paramsKeys = ['client_id', 'redirect_uri', 'response_type', 'scope', 'state'];
export const isBrowser = (): boolean =>
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined';

export enum consultationTypeEnum {
  TEXTIN_SCENE = 1, //场景智能文字识别引擎
  TEXTIN_TEXT, //通用文字识别
  TEXTIN_IMAGE, //图像智能处理
  TEXTIN_STUDIO_STRUCT, //TextIn Studio自配置结构化工具
  TEXTIN_STUDIO, //文字识别训练平台
  TEXTIN_BILL_ROBOT, //票据机器人
  TEXTIN_CONTRACT_ROBOT, //合同比对
  TEXTIN_FINANCE_ROBOT, //财报机器人
  TEXTIN_COMPANY_AI, //AI管理平台
  TEXTIN_MOBILE_SDK, //Mobile SDK
  TEXTIN_DEPLOY, //私有化部署
  TEXTIN_SOLUTION, //解决方案
  TEXTIN_ACCOUNT_SAFETY, //账号安全
  TEXTIN_FINANCE_QUESTION, //财务问题
  TEXTIN_CONSULT, //售前咨询
  TEXTIN_PARTNER, //合作伙伴支持
  TEXTIN_SUGGESTION, //意见反馈
  TEXTIN_OTHERS, //其他
  TEXTIN_PLATE_MADE, //板式定制
  TEXTIN_ROBOT_LAB, //机器人实验室
  TEXTIN_CARD, //卡证文字识别
  TEXTIN_BILL, //票据文字识别
  TEXTIN_CONVERSION, //文档格式转换
  TEXTIN_IHCR, //智能人机协同录入
  TEXTIN_DOCFLOW = 27, // DocFlow
  TEXTIN_CONTRACT_EXTRACTION_ROBOT = 29, // 合同抽取机器人
}
export const dashboardSitePrefixPath = '/console';

export const setLocalItem = (key: string) => (val: any) => localStorage.setItem(key, val);

function getLocalItem(key: string) {
  return (key2?: string) => {
    let val: string | null;
    try {
      val = localStorage.getItem(key2 || key);
    } catch (e) {
      return null;
    }

    return val;
  };
}

export const getToken = getLocalItem('_token');
export const setToken = (token) => {
  setLocalItem('_token')(token);
  setTokenCookie(token);
};

export const setLocalUserInfo = (
  info: Record<string, any>,
  { merge = false }: { merge?: boolean } = {},
) => {
  try {
    const strInfo = JSON.stringify(merge ? { ...getLocalUserInfo(), ...info } : info);
    setLocalItem('__user_info__')(strInfo);
    const token = getToken();
    setTokenCookie(token);
  } catch (error) {}
};
export const getLocalUserInfo = (): Record<string, any> => {
  try {
    const strInfo = getLocalItem('__user_info__')();
    if (strInfo) {
      return JSON.parse(strInfo);
    }
  } catch (error) {}
  return {};
};

export const isLogin = () => {
  return getLocalUserInfo() && getToken();
};

export const isNil = (value: any) => value === null || value === undefined;

export const BASE_IMG_URL = `${getRequestEnvUrl()}/open/image/download`;
export const getImgUrlAndFallback = (filename?: string) => {
  const fallback =
    'https://web-api.textin.com/open/image/download?filename=5786c9d3462540d8a2f6b8ce2760b97f';
  return filename ? `${BASE_IMG_URL}?filename=${filename}` : fallback;
};

export enum target {
  '_self' = '_self',
  '_blank' = '_blank',
}

// 重定向url限制
export const getRedirectUrl = (url: string) => {
  const urlReg =
    /^(((https?:\/\/)?[a-z-_0-9\.]+\.(textin|intsig)\.(com|net).*)|((\/.+)+)(\?[a-zA-Z0-9]+=.*)?)$/g;
  return urlReg.test(url) && !url.includes('@') ? url : '/';
};
export const getPageVisible = (query: any) => {
  const token = getToken();
  return paramsKeys.every((key) => Object.keys(query).indexOf(key) > -1) && !!token;
};
export const getRedirectWithSSOPath = (params: any) => {
  return paramToString(params, `${ssoContractsUrl[getEnv()]}/oauth2/authorize`);
};

// 不展示【私有化部署】相关内容的产品id
export const disablePrivateDeploy = [
  'verify_vat',
  'blockchain_validate',
  'pdf-to-word',
  'doc_restore',
  'pdf-to-excel',
  'pdf-to-ppt',
  'pdf-to-image',
  'word-to-pdf',
  'excel-to-pdf',
  'image-to-pdf',
  'word-to-image',
  'textin_conversion',
];
