export function updateUrlQueryString(
  queryParams: Record<string, string | null | undefined>,
  url?: string,
): string {
  const currentUrl = url || window.location.href;

  // Split URL and hash
  const [urlWithoutHash, hash] = currentUrl.split('#');
  const [baseUrl, queryString] = urlWithoutHash.split('?');

  // Parse current query parameters
  const currentParams: Record<string, string> = queryString
    ? queryString.split('&').reduce((acc, param) => {
        const [k, v] = param.split('=');
        if (k) {
          acc[decodeURIComponent(k)] = v ? decodeURIComponent(v) : '';
        }
        return acc;
      }, {} as Record<string, string>)
    : {};

  // Update query parameters
  for (const key in queryParams) {
    const value = queryParams[key];
    if (value === null || value === undefined) {
      delete currentParams[key];
    } else {
      currentParams[key] = value;
    }
  }

  // Construct new query string
  const newQueryString = Object.keys(currentParams)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(currentParams[k] || '')}`)
    .join('&');

  // Reconstruct URL with new query string and hash
  return newQueryString
    ? `${baseUrl}?${newQueryString}${hash ? `#${hash}` : ''}`
    : `${baseUrl}${hash ? `#${hash}` : ''}`;
}

export function updateSearchParams(queryParams: Record<string, string | null>): void {
  const newUrl = updateUrlQueryString(queryParams);
  window.history.replaceState({}, '', newUrl);
}

export function getUrlSearchParams(url?: string): Record<string, string> {
  const search = url ? new URL(url).search : window?.location.search;
  const searchParams = new URLSearchParams(search);
  const searchObj: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    searchObj[key] = value;
  });

  return searchObj;
}

export function parseUrl(url: string) {
  let origin = '';
  let pathname = '';

  const urlPattern = /^(https?:\/\/(?:localhost|[^/]+))?(\/.*)?$/;
  const match = url.match(urlPattern);

  if (match) {
    origin = match[1] || '';
    pathname = match[2] || '/';
  }

  return { origin, pathname };
}

export function isDocflowPath(pathname?: string) {
  const currentPath = pathname || location.pathname;
  return currentPath.includes('docflow');
}

export function isContractPath(pathname?: string) {
  // 判断 合同比对 路径
  const currentPath = pathname || location.pathname;
  const currenSearch = location.search;
  const flag =
    currentPath.includes('/product/textin_contract') ||
    currentPath.includes('/chager/doc_compare') ||
    (currentPath.includes('contact') && currenSearch.includes('type=7'));

  if (flag || currentPath.includes('document/doc_compare')) {
    localStorage.setItem('comeToContract', 'true');
  } else {
    localStorage.setItem('comeToContract', 'false');
  }

  return flag;
}

export function addCompareSource(LeadsType: any) {
  const currentPath = location.pathname;
  const currenSearch = location.search;

  if (currentPath.includes('/chager/doc_compare')) {
    return `source=${LeadsType.contactComparePrice}`;
  } else if (currentPath.includes('/product/textin_contract')) {
    return `source=${LeadsType.contactCompareRegister}`;
  } else if (currentPath.includes('contact') && currenSearch.includes('type=7')) {
    return `source=${LeadsType.contactCompareRegister}`;
  }
}
