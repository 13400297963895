import { getLocalUserInfo } from '@/utils';
import leads from '@/utils/leads';
import { amountOptions, roleOptions } from './config';

export const transformUserPreferedToLeadsData = (userPrefered: any) => {
  const demands = userPrefered?.demands;
  const profile = userPrefered?.profile;
  return {
    company: profile?.company ? profile?.company : undefined,
    company_id: profile?.company_id ? profile?.company_id : undefined,
    position: profile?.department ? profile?.department : undefined,
    email: profile?.email ? profile?.email : undefined,
    remark: demands
      ? `角色：${demands?.role || '-'}\n` +
        `文件类型：${demands?.document || '-'}\n` +
        `文件类型具体描述：${demands?.document_desc || '-'}\n` +
        `使用场景：${demands?.scene || '-'}\n` +
        `月调用量：${demands?.amount || '-'}\n`
      : undefined,
  };
};

export const updateLeadsInfo = (leadsIds: string[], userPrefered: any) => {
  if (leadsIds) {
    leadsIds.forEach((id: string) => {
      leads({
        leads_id: id,
        ...transformUserPreferedToLeadsData(userPrefered),
      });
    });
  }
};

export const isLowDamandsUser = (userInfo?: Record<string, any>) => {
  const currentUser = userInfo || getLocalUserInfo();
  const userPrefered = currentUser?.userPrefered;
  return (
    userPrefered?.demands?.role === roleOptions[0].value &&
    [amountOptions[0].value, ...(amountOptions[0].oldValues || [])].includes(
      userPrefered?.demands?.amount,
    )
  );
};
