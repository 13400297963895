import request from './request';
import { getUrlSearchParams, isDocflowPath, isContractPath, addCompareSource } from './route';
import { sentryLog } from './sentry';
import { track } from './track';

export const LeadsType = {
  register: 'TextIn自然流量-注册(不计入B端市场线索)',
  contact: 'TextIn自然流量-留言(不计入B端市场线索)',
  docflow: 'docflow-register',
  docflowContact: 'docflow-privatization',
  contactCompareRegister: 'htbd-product-register',
  contactComparePrice: 'htbd-price-register',
  contactCompareExperience: 'htbd-experience-register',
  contractComparePrivatization: 'htbd-privatization',
  contractCompareCompelete: 'htbd-complete',
};

export const getTextinLeadsQuery = () => {
  if (isDocflowPath()) {
    return `source=${LeadsType.docflow}`;
  }
  if (isContractPath()) {
    const sourceType = addCompareSource(LeadsType);
    return sourceType;
  }
  return '';
};

export const getLeadsParams = () => {
  const searchObj = getUrlSearchParams();
  if (searchObj.from) {
    return searchObj;
  }
  const cachedleads = window.localStorage.getItem('crm_leads');
  if (cachedleads) {
    try {
      const obj = JSON.parse(cachedleads);
      return obj.search;
    } catch (error) {}
  }
  return {};
};

export const getExtraParams = () => {
  if (typeof window.get_crm_leads === 'function') {
    const res = window.get_crm_leads();
    if (res?.from) {
      return { source: res.from };
    }
  }
  return {};
};

const leads = async function (data: Record<string, unknown>) {
  track({ name: 'leads', keyword: 'leads请求发送' });
  try {
    const res = await request('/api/crm/leads', {
      method: 'POST',
      data: {
        ...data,
        ...getExtraParams(),
      },
      prefix: '',
    });
    track({ name: 'leads成功', message: res });
    console.info('leads-res', res);
  } catch (error) {
    track({
      name: 'leads报错',
      message: String(error) !== '[object Object]' ? String(error) : JSON.stringify(error),
    });
    sentryLog.error('leads报错', error);
    console.error('leads-error', error);
  }
};

export default leads;
