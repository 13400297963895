import { isBrowser } from './common';
export enum env {
  TEST = 'test',
  PRE = 'pre',
  PRODUCTION = 'production',
}
export const getServiceEnv = (host: string) => {
  if (host?.includes('k8s')) {
    return env.TEST;
  }
  return getEnv(host);
};
export const getEnv = (serverHost?: string) => {
  const host = isBrowser() ? window.location.host : serverHost || '';
  if (host.includes('test') || host.includes('localhost') || host.includes('192.168')) {
    return env.TEST;
  }
  if (host.includes('pre')) {
    return env.PRE;
  }
  return env.PRODUCTION;
};

export const isProduction = () => getEnv() === env.PRODUCTION;

export const ssoUrl = {
  [env.TEST]: 'https://web-sso-sandbox.intsig.net',
  [env.PRE]: 'https://web-sso.intsig.net',
  [env.PRODUCTION]: 'https://web-sso.intsig.net',
};
export const ssoContractsUrl = {
  [env.TEST]: 'http://textin-sso-test.ai.intsig.net',
  // [env.TEST]: 'http://oauth2.textin-k8s.intsig.net',
  // [env.TEST]: 'https://auth.textin.com',
  [env.PRE]: 'https://auth.textin.com',
  [env.PRODUCTION]: 'https://auth.textin.com',
};
export const reqEnvUrl = {
  [env.TEST]: 'https://textin-sandbox.intsig.com',
  // [env.TEST]: 'https://api.textin.com',
  [env.PRE]: 'https://textin-api-pre.intsig.com',
  [env.PRODUCTION]: 'https://web-api.textin.com',
  // [env.PRODUCTION]: 'https://web-api.textin.com',
};

export const ocrEnvUrl = {
  [env.TEST]: 'https://textin-sandbox.intsig.com',
  // [env.TEST]: 'https://api.textin.com',
  [env.PRE]: 'https://textin-api-pre.intsig.com',
  [env.PRODUCTION]: 'https://api.textin.com',
};

const intfinqUrl = {
  [env.TEST]: 'https://intfinq-sandbox.textin.com/sso?sso=textin',
  [env.PRE]: 'https://intfinq.textin.com/sso?sso=textin',
  [env.PRODUCTION]: 'https://intfinq.textin.com/sso?sso=textin',
};

const envTextinSiteUrl = {
  [env.TEST]: 'https://test.textin.com',
  [env.PRE]: 'https://pre.textin.com',
  [env.PRODUCTION]: 'https://www.textin.com',
};

const chatUrl = {
  [env.TEST]:
    'https://affim.baidu.com/unique_53420947/chat?siteId=20179442&userId=53420947&siteToken=d7739cca5da0b9b24f2bf95e85c0b601',
  [env.PRE]:
    'https://affim.baidu.com/unique_53420947/chat?siteId=20179442&userId=53420947&siteToken=d7739cca5da0b9b24f2bf95e85c0b601',
  [env.PRODUCTION]:
    'https://affim.baidu.com/unique_53420947/chat?siteId=20179010&userId=53420947&siteToken=4c9ce7e524cff82116003b737c8cbd73',
};

const contractUrl = {
  [env.TEST]:
    'https://contracts-sandbox.textin.com/textin_for_contracts/user/login?sso_name=TextIn',
  [env.PRE]: 'https://contracts.textin.com/textin_for_contracts/user/login?sso_name=TextIn',
  [env.PRODUCTION]: 'https://contracts.textin.com/textin_for_contracts/user/login?sso_name=TextIn',
};

const toolsUrl = {
  [env.TEST]: 'https://temp-textin-tools.ai.intsig.net',
  [env.PRE]: 'https://textin-tools.ai.intsig.net',
  [env.PRODUCTION]: 'https://tools.textin.com',
};

const studioUrl = {
  [env.TEST]: 'https://test.textin.com/studio',
  [env.PRE]: 'https://www.textin.com/studio',
  [env.PRODUCTION]: 'https://www.textin.com/studio',
};

const docFlowUrl = {
  // [env.TEST]: 'https://docflow-sandbox.textin.com/sso.html',
  [env.TEST]: 'https://bill-test-zw.textin.com/sso-test.html',
  [env.PRE]: 'https://docflow-pre.textin.com/sso.html',
  [env.PRODUCTION]: 'https://docflow.textin.com/sso.html',
};

// 静态资源拼接的域名
const assetEnvUrl = {
  [env.TEST]: 'https://textin-sandbox.intsig.com',
  [env.PRE]: 'https://web-api.textin.com',
  [env.PRODUCTION]: 'https://web-api.textin.com',
};

export const getRequestEnvUrl = () => reqEnvUrl[getEnv()];
export const getOCRRequestEnvUrl = () => ocrEnvUrl[getEnv()];
export const getIntFinQSiteUrl = () => intfinqUrl[getEnv()];
export const getTextinSiteUrl = () => envTextinSiteUrl[getEnv()];
export const getTextinDashboardSiteUrl = () => `${getTextinSiteUrl()}/console`;
export const getContractUrl = () => contractUrl[getEnv()];
export const getToolsUrl = () => toolsUrl[getEnv()];
export const getStudioUrl = () => studioUrl[getEnv()];
export const getDocFlowUrl = () => docFlowUrl[getEnv()];
export const getChatUrl = () => chatUrl[getEnv()];
export const getBaiduHm = () => {
  if (getEnv() !== env.PRODUCTION) {
    return 'https://hm.baidu.com/hm.js?d7739cca5da0b9b24f2bf95e85c0b601';
  }
  return 'https://hm.baidu.com/hm.js?4c9ce7e524cff82116003b737c8cbd73';
};

export const getAssetEnvUrl = () => assetEnvUrl[getEnv()];

// 百度地图
export const mapUrl = `https://api.map.baidu.com/api?type=webgl&v=1.0&ak=E4805d16520de693a3fe707cdc962045&callback=initMap`;

export const isProdMode = process.env.NODE_ENV === 'production';
