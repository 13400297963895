import { consultationTypeEnum, getContractUrl, getToken, target } from '@/utils';

export const contract_service = 'doc_compare';
export const contract_service_2 = 'doc_extraction';

export const toContract = (module = 'extraction') => {
  const contractURL = `${getContractUrl()}&modules=${module}`;
  const service = module === 'compare' ? contract_service : contract_service_2;
  const url = `/activate/contract?service=${service}&redirect=${encodeURIComponent(contractURL)}`;
  if (getToken()) {
    window.open(url);
  } else {
    window.open(`/user/login?source=htbd-product-register&redirect=${encodeURIComponent(url)}`);
    localStorage.setItem('contract_mudoles_permission', module);
  }
};

export const contractBannerData = {
  title: 'TextIn 合同比对',
  desc: '依托于合合信息强大的文档解析、元素比对技术，提供多格式文档一键比对、差异精准定位、差异结果一键导出等功能。能精准找出不同文档文本段落、表格、印章之间的差异，分钟级别完成百页文档比对。显著降低企业在合同管理全流程中的人工审核成本，减少因合同差异带来的潜在风险。',
  backgroundImage: '/images/product/banner_contract.jpg',
  btnText: '在线试用',
};

export const contractCompareBannerData = {
  title: 'TextIn 合同抽取',
  desc: 'TextIn合同抽取为您提供合同文档智能化信息抽取服务。该服务基于合合信息强大的文字识别能力，结合了文档解析、文档检索和文本生成三项核心技术。提供多类型合同文档的关键要素提取和比对的能力。',
  backgroundImage: '/images/product/banner_contract.jpg',
  btnText: '在线试用',
};

export const contractVideoData = {
  title: '产品介绍',
  source: '//static.textin.com/videos/web/product/Textin_contract_robot.mp4',
};

export const contractPlatformSceneData = {
  title: '产品功能',
  sceneList: [
    {
      name: '强大的文档比对引擎',
      desc: '支持跨行、跨页及全文比对，支持灵活配置比对规则，实现印章、标点符号等元素比对。可分钟级完成百页文档比对，准确率超90%。',
      image: '/images/contract/func_all.png',
    },
    {
      name: '精准的差异定位标注',
      desc: '支持文本、表格、印章不同元素差异展示。差异项高亮标注，跨文档连线，一目了然，帮助合同相关部门快速识别和处理文档差异。',
      image: '/images/contract/func_diff.png',
    },
    {
      name: '全面的文档格式支持',
      desc: '支持pdf、doc、xls、图像等格式的文档比对，覆盖文档比对全场景，如多版本修订稿、定稿电子合同和用印扫描件比对等。',
      image: '/images/contract/func_doc.png',
      xx: '支持上传Word、PDF、图片格式文档进行比对，合同审批流程中最终版“电子档文件”与双方盖章的“用印扫描件”进行快速比对',
    },
    {
      name: '多样的差异查阅方式',
      desc: '支持在预览界面对差异结果进行审阅，也可以一键导出批注文档、差异统计报告，供多个合同相关方线下查阅差异。',
      image: '/images/contract/func_search.png',
    },
  ],
};

export const contractExtractionPlatformSceneData = {
  title: '产品功能',
  sceneList: [
    {
      name: '强大的信息抽取引擎',
      desc: '结合自研版面分析引擎，实现多种合同场景下非结构化文档的高精度信息抽取。',
      image: '/images/contract/func_all.png',
    },
    {
      name: '灵活的抽取字段配置',
      desc: '支持自定义抽取字段，无需标注训练，自动完成各类合同关键信息抽取。',
      image: '/images/contract/func_diff.png',
    },
    {
      name: '全面的文档格式支持',
      desc: '支持pdf、doc、xls、图像等格式的文档抽取，覆盖合同生命周期全场景。',
      image: '/images/contract/func_doc.png',
      xx: '支持上传Word、PDF、图片格式文档进行比对，合同审批流程中最终版“电子档文件”与双方盖章的“用印扫描件”进行快速比对',
    },
    {
      name: '精准的关键信息比对',
      desc: '支持对多文档抽取的关键信息做比对，关键信息跨文档连线，一目了然。',
      image: '/images/contract/func_search.png',
    },
  ],
};

export const contractAdvanceData = [
  {
    logo: '/images/contract/icon_contract_adv_02.png',
    title: '领先的技术能力',
    desc: '依托于合合信息的高精度OCR、解析引擎、比对引擎等核心自研技术，支持图片模糊、倾斜等复杂场景的文字识别需求，提供业界顶尖的文档比对速度和准确度',
  },
  {
    logo: '/images/contract/icon_contract_adv_03.png',
    title: '灵活的集成方式',
    desc: '支持公有云saas服务、私有化部署、API集成多种集成方式，支持与企业内部管理系统（如：ERP系统、OA系统等）无缝对接，支持多种集成方式',
  },
  {
    logo: '/images/contract/icon_contract_adv_04.png',
    title: '丰富的行业实践',
    desc: '在金融、银行、制造业、法律、通信、法律、审计、房地产等50+行业深度实践，覆盖租赁合同、信贷合同、信托合同、采购合同、工程合同等典型合同场景的比对',
  },
];

export const contractExtractionAdvanceData = [
  {
    logo: '/images/contract/icon_contract_adv_02.png',
    title: '领先的技术能力',
    desc: '结合自研版面分析引擎，准确还原文档中复杂表格、双栏排版等结构，实现对各类版式文档的高精度抽取。',
  },
  {
    logo: '/images/contract/icon_contract_adv_03.png',
    title: '精准的语义理解',
    desc: '基础数据覆盖金融、法律等行业的高质量语料，模型既具备通识能力，也具备不同行业的专项领域知识。',
  },
  {
    logo: '/images/contract/icon_contract_adv_04.png',
    title: '灵活的集成方式',
    desc: '支持公有云saas服务、私有化部署、API集成多种集成方式，支持与企业内部管理系统（如：ERP系统、OA系统等）无缝对接，支持多种集成方式。',
  },
];

export const contractSceneData = {
  title: '应用场景',
  sceneList: [
    {
      image: '/images/contract/pic_contract_scene_01@2x.png',
      name: '保险合同管理',
      desc: '帮助保险企业将OA系统中的过审合同与风险系统中的用印版合同进行比对，如存在差异即发出预警；将承办方合同与同类模板条款比对，提醒差异、缺少情况，规避合同伪造风险',
    },
    {
      image: '/images/contract/pic_contract_scene_02@2x.png',
      name: '银行信贷审批',
      desc: '帮助银行对租赁合同、经营实地场所、农村产权承包证等材料进行印章存在性判存、手写签字判存，对待审议合同与合同模板进行比对，判断条款是否有增、删、改',
    },
    {
      image: '/images/contract/pic_contract_scene_03@2x.png',
      name: '采购防范风险',
      desc: '帮助企业采购部门在前期洽谈合同环节中，防范“阴阳合同”、函证造假风险，识别纸质合同上的差异并展示差异结果',
    },
    {
      image: '/images/contract/pic_contract_scene_04@2x.png',
      name: '供应链金融合同审阅',
      desc: '供应链金融业务中，银行作为资金提供方，需要对合作伙伴提供的合同材料进行关键信息的抽取与审阅，用于贷款审核与风险管理',
    },
    {
      image: '/images/contract/pic_contract_scene_05@2x.png',
      name: '企业法务合规风控',
      desc: '为了防止用印合同与电子版合同存在关键性差异，对合同关键信息进行抽取与比对，防范重大合同风险，通过辅助法务人员审查合同，帮助法务人员从重复劳动中解放出来，提升审核专业水平',
    },
    {
      image: '/images/contract/pic_contract_scene_06@2x.png',
      name: '合同数据资产化管理',
      desc: '对合同关键信息进行识别抽取，生成指定字段数据，对接企业业务系统或存储入内部数据库，便于后续业务的数据追踪、履约情况管理，帮助企业洞察及研判经营状况，提升运营和数据治理能力',
    },
  ],
};

export const contractPartnerList = {
  image: '/images/contract/pic_customer_contract_bg@2x.png',
  partnerList: [
    {
      logo: '/images/contract/scene_logo_spd-bank_east@2x.png',
      companyInfo:
        '合合信息TextIn合同机器人对作为AI智能合同审阅专家，帮助法务部门进行合同比对，规避用印合同与电子版合同存在差异带来的损失风险，助力企业提升运营效率。',
      companyName: '—— 东方希望',
    },
    {
      logo: '/images/contract/scene_logo_spd-bank_tigermed@2x.png',
      companyInfo:
        '合合信息TextIn合同机器人将合同文本进行自动识别与精准智能比对，快速展示差异结果，从而帮助泰格医药降低合同风险，避免重大损失，同时提升工作效率，减少重复劳动，降低用人成本。',
      companyName: '—— 泰格医药',
    },
    {
      logo: '/images/contract/scene_logo_spd-bank_ccb@2x.png',
      companyInfo:
        '人工比对合同容易疏忽细微差异，依赖审核人员个体的业务素养、体力、精神状态，准确率无法保证，业务方或合约方可能篡改合同，导致合同风险与重大损失。建信信托引入合合信息TextIn合同机器人，为客户提供私有化部署方式，保证合同文档安全性，集成客户方业务系统，采用单点登录集成方式。',
      companyName: '—— 建信信托',
    },
  ],
};

const privacyDelpoy = {
  title: '私有化部署',
  logo: '/images/scene/pic_deploy_open-cloud_02@2x.png',
  desc: '可部署至本地服务器，进一步保障数据安全。支持 GPU、CPU 环境及国产化操作系统部署',
  anchors: [{ href: `/contact?type=${consultationTypeEnum.TEXTIN_DEPLOY}`, text: '提交需求' }],
  cardLink: `/contact?type=${consultationTypeEnum.TEXTIN_DEPLOY}`,
};

export const deployList = [
  {
    title: '公有云API',
    logo: '/images/scene/pic_deploy_open-cloud_01@2x.png',
    desc: '16年技术沉淀，安全、可靠、迅速的公有云服务，可直接调用API使用TextIn智能文档识别产品',
    anchors: [{ text: '立即体验' }],
    cardLink: () => toContract('compare'),
    target: target._blank,
  },
  privacyDelpoy,
];

export const deployExtractionList = [
  {
    title: '公有云API',
    logo: '/images/scene/pic_deploy_open-cloud_01@2x.png',
    desc: '16年技术沉淀，安全、可靠、迅速的公有云服务，可直接调用API使用TextIn智能文档识别产品',
    anchors: [{ text: '立即体验' }],
    cardLink: () => toContract('extraction'),
    target: target._blank,
  },
  privacyDelpoy,
];
